import React from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Routes from './Components/Routes/Routes';
import Footer from './Components/Footer/Footer';

function App() {
  //const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => {
  //   setIsOpen(!isOpen);
  // };
  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <main>
        <Routes />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
