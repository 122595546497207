export const ROUTES = {
    HOME: "/",
    VALUATION: "/Wyceny",
    POLICY: "/PolitykaPrywatnosci",
};

export const ENGLISHROUTES = {
    HOME: "/",
    VALUATION: "/Valuations",
    POLICY: "/PolitykaPrywatnosci",
};