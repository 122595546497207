import React, { useEffect, useState } from 'react';
import { Paper, Typography, Button, TextField, List, ListItem, ListItemText, Divider, IconButton, MenuItem, Select, SelectChangeEvent, InputLabel, FormControl } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import { Helmet } from 'react-helmet';
import './Valuation.css';
import { IValuationItem } from '../../Interfaces/IValuationItem';
import useData from '../../Hooks/useData';
import { InputLabelProps, InputProps, sxPaper, sxButton, sx, NumberInputProps, sxSummary } from './ValuationProps';
import { IStoredItems } from '../../Interfaces/IStoredItems';

const validationSchema = Yup.object({
  name: Yup.string().required('Nazwa jest wymagana'),
  quantity: Yup.number()
    .required('Ilość jest wymagana')
    .min(0.01, 'Ilość musi być większa niż 0')
    .max(10000, 'Ilość nie może być większa niż 10000'),
  unitPrice: Yup.number()
    .required('Cena jednostkowa jest wymagana')
    .min(0.01, 'Cena jednostkowa musi być większa niż 0')
    .max(1000000, 'Cena jednostkowa nie może być większa niż 1000000'),
  unit: Yup.string().required('Jednostka jest wymagana'),
  currency: Yup.number().required('Waluta jest wymagana'),
});

const Valuation: React.FC = () => {
  const [items, setItems] = useState<IValuationItem[]>([]);
  const [currencyId, setCurrencyId] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState<string>('zł');
  const { getPdfResults } = useData();

  useEffect(() => {
    loadItemsFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItemsFromLocalStorage = () => {
    const storedData = localStorage.getItem('items');
    if (storedData) {
      const parsedData: IStoredItems = JSON.parse(storedData);

      if (parsedData.expiry > Date.now()) {
        setItems(parsedData.items);
        setCurrencyId(parsedData.currencyId);
        setCurrencySymbol(getCurrencySymbol(parsedData.currencyId));
      } else {
        localStorage.removeItem('items');
      }
    }
  };

  const setItemsToLocalStorage = (items: IValuationItem[]) => {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const expiry = Date.now() + oneDayInMilliseconds;

    const dataToStore: IStoredItems = {
      items: items,
      currencyId: currencyId,
      expiry: expiry,
    };

    setItems(items);
    if (items.length !== 0) {
      localStorage.setItem('items', JSON.stringify(dataToStore));
    } else {
      localStorage.removeItem('items');
    }
  };


  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    if (items.length === 0) {
      setCurrencyId(event.target.value as number);
    }
  };

  const handleAddItem = (values: IValuationItem, { resetForm }: any) => {
    const newItem: IValuationItem = {
      name: values.name,
      quantity: values.quantity,
      unitPrice: values.unitPrice,
      unit: values.unit,
    };
    setCurrencySymbol(getCurrencySymbol(currencyId));
    setItems([...items, newItem]);
    setItemsToLocalStorage([...items, newItem]);
    resetForm();
  };

  const handleRemoveItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
    setItemsToLocalStorage(items.filter((_, i) => i !== index));
  };

  const handleClearItems = () => {
    setItems([]);
    setItemsToLocalStorage([]);
  };

  const getCurrencySymbol = (currencyId: number) => {
    switch (currencyId) {
      case 1:
        return 'zł'
      case 2:
        return '€';
      case 3:
        return ('$');
      case 4:
        return '£';
      default:
        return 'zł';
    }
  }

  const generatePdf = async () => {
    const req = {
      Items: items,
      FileName: "Wycena dla Jan Kowalski",
      Title: "Wycena",
      Contractor: "Wykonawca \nTelefon: +48 123 456 789",
      Customer: "Jan Kowalski\nTelefon: +48 123 789 789",
      CurrencyId: currencyId,
      AddCustomer: false,
      AddContractor:false,
      AddSignature: true,
    }
    const response = await getPdfResults(req);
    if (response && !response.succeed) {
      console.error('Error while generating pdf: ', response.message);
    }
  }

  return (
    <div className="valuation-container">
      <Helmet>
        <title>Wyceny</title>
      </Helmet>

      {/* Header */}
      <Typography variant="h1" className="header">
        Kalkulator wycen usług
      </Typography>

      {/* Description */}
      <Typography variant="h2" className="description">
        Oblicz wartość swoich usług w prosty sposób. Wprowadź nazwę, cenę jednostkową, ilość i jednostkę, a następnie kliknij przycisk "Dodaj". Wszystkie zostaną wyświetlone po prawej stronie. Możesz je usunąć, klikając na ikonę kosza. Poniżej znajdziesz podsumowanie. Wartość  jest obliczana na bieżąco. W razie potrzeby możesz zmienić wartości lub usunąć je. Powodzenia!
      </Typography>

      <div className="main-content">
        <div className="form-section">
          <Paper
            sx={sxPaper}
            className="form-paper"
          >
            <Typography variant="h5" className="form-title">
              Dodaj
            </Typography>
            <Formik
              initialValues={{ name: '', quantity: 0, unitPrice: 0, unit: '', currency: 1 }}
              validationSchema={validationSchema}
              onSubmit={handleAddItem}
            >
              {({ errors, touched, handleChange }) => (
                <Form>
                  <Field
                    as={TextField}
                    label="Nazwa"
                    name="name"
                    fullWidth
                    margin="normal"
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    onChange={handleChange}
                    sx={{
                      ...sx,
                      marginBottom: '1.5em',
                    }}
                    InputLabelProps={InputLabelProps}
                    InputProps={InputProps}
                  />
                  <div className="two-column-container">
                    <Field
                      as={TextField}
                      label="Cena jednostkowa"
                      name="unitPrice"
                      type="number"
                      fullWidth
                      margin="normal"
                      error={touched.unitPrice && !!errors.unitPrice}
                      helperText={touched.unitPrice && errors.unitPrice}
                      onChange={handleChange}
                      sx={sx}
                      InputLabelProps={InputLabelProps}
                      InputProps={NumberInputProps}
                    />
                    <FormControl fullWidth>

                    <InputLabel id='combo-input-currency' className="combo-input-currency">
                      Waluta
                    </InputLabel>
                    <Select
                      labelId='combo-input-currency'
                      // label="Waluta"
                      name="currency"
                      value={currencyId}
                      onChange={handleSelectChange}
                      fullWidth
                      disabled={items.length > 0}
                      sx={{
                        ...sx,
                        marginTop: '1em',
                        marginBottom: '0.5em',
                      }}
                    >
                      <MenuItem key={1} value={1}>
                        PLN
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        EUR
                      </MenuItem>
                      <MenuItem key={3} value={3}>
                        USD
                      </MenuItem>
                      <MenuItem key={4} value={4}>
                        FUNT
                      </MenuItem>
                    </Select>
                    </FormControl>
                  </div>
                  <div className="two-column-container">
                    <Field
                      as={TextField}
                      label="Ilość"
                      name="quantity"
                      type="number"
                      fullWidth
                      margin="normal"
                      error={touched.quantity && !!errors.quantity}
                      helperText={touched.quantity && errors.quantity}
                      onChange={handleChange}
                      sx={sx}
                      InputLabelProps={InputLabelProps}
                      InputProps={NumberInputProps}
                    />
                    <Field
                      as={TextField}
                      label="Jednostka"
                      name="unit"
                      fullWidth
                      margin="normal"
                      error={touched.unit && !!errors.unit}
                      helperText={touched.unit && errors.unit}
                      onChange={handleChange}
                      sx={sx}
                      InputLabelProps={InputLabelProps}
                      InputProps={InputProps}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={sxButton}
                  >
                    Dodaj
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>

          {/* Summary */}
          <Paper
            sx={sxSummary}
            className="summary-paper"
          >
            <Typography variant="h5" className="summary-title">
              Podsumowanie
            </Typography>
            <Typography variant="body1">
              Liczba : {items.length}
            </Typography>
            <Typography variant="body1">
              Łącznie: {items.reduce((acc, item) => acc + item.quantity * item.unitPrice, 0).toFixed(2)} {currencySymbol}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="button"
              sx={{
                ...sxButton,
                backgroundColor: '#007bff',
                '&:hover': {
                  backgroundColor: '#0056b3',
                }
              }}
              disabled={items.length === 0}
              onClick={() => generatePdf()}
            >
              Wygeneruj pdf
            </Button>
          </Paper>
        </div>
        <div className='list-container'>
          {/* List */}
          <Paper
            sx={{
              padding: '2vh 4vw',
              borderRadius: '2em',
              boxShadow: '0 0.5em 1.5em rgba(0, 0, 0, 0.3)',
              backgroundColor: '#1e1e1e',
              border: '1px solid #333333',
              color: '#ffffff',
            }}
            className="list-paper"
          >
            <Typography variant="h5" className="list-title">
              Lista
            </Typography>
            <List>
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={item.name}
                      secondaryTypographyProps={{ sx: { color: '#ffffff' } }}
                      secondary={`${item.quantity.toFixed(2)} ${item.unit} x ${item.unitPrice.toFixed(2)} ${currencySymbol}/${item.unit}`}
                    />
                    <IconButton onClick={() => handleRemoveItem(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  {index < items.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
            <Button
              variant="contained"
              color="error"
              type="button"
              sx={{
                ...sxButton,
                marginTop: 'auto',
                backgroundColor: '#aa2e25',
                '&:hover': {
                  backgroundColor: '#f44336',
                },
              }}
              disabled={items.length === 0}
              onClick={() => handleClearItems()}
            >
              Wyczyść
            </Button>
          </Paper>
        </div>
      </div>
    </div >
  );
};

export default Valuation;
