const InputLabelProps = { style: { color: '#bbbbbb' } }
const InputProps = { style: { color: '#ffffff' } }
const sxPaper = {
    padding: '2vh 4vw',
    borderRadius: '2em',
    boxShadow: '0 0.5em 1.5em rgba(0, 0, 0, 0.3)',
    backgroundColor: '#1e1e1e',
    border: '1px solid #333333',
    color: '#ffffff',
}

const sxButton = {
    borderRadius: '1.5em',
    color: '#ffffff',
    marginTop: '2vh',
}

const sx = {
    borderRadius: '2em',
    backgroundColor: '#333333',
    color: '#ffffff',
}

const NumberInputProps = {
    style: { color: '#ffffff' },
    inputProps: {
        step: 0.01,
        min: 0,
        max: 1000000,
    },
}

const sxSummary = {
    padding: '2vh 4vw',
    borderRadius: '2em',
    boxShadow: '0 0.5em 1.5em rgba(0, 0, 0, 0.3)',
    backgroundColor: '#1e1e1e',
    border: '1px solid #333333',
    color: '#ffffff',
    marginTop: '2vh',
}

export { InputLabelProps, InputProps, sxPaper, sxButton, sxSummary, sx, NumberInputProps }