import React, { useEffect } from 'react';
import './Home.css';
import useData from '../../Hooks/useData';
import { IAppsData } from '../../Interfaces/IAppsData';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
    const [apps, setApps] = React.useState<IAppsData[]>([]);
    const { getAppsData } = useData();
    const navigate = useNavigate();
    useEffect(() => {
        const getApps = async (langId?: number) => {
            const response = await getAppsData(langId);
            if (response.succeed) {
                const data = JSON.stringify(response?.data);
                const apps = JSON.parse(data);
                const appsData: IAppsData[] = apps.apps;
                setApps(appsData);
            }
        };
        getApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='container'>
            <h1 className='home-h1'>Home Page</h1>
            <div className="grid-container">
                {apps.map(item => (
                    <div key={item.id} 
                        className="item"
                        onClick={() => navigate(item.url)}
                    >
                        <img src={`https://dummyimage.com/150x150/000/fff&text=${encodeURIComponent(item.name)}`} alt={item.name} className="item-image" />
                        <h2 className="item-name">{item.name}</h2>
                        <p className="item-description">{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;