import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Valuation from '../../Pages/Valuation/Valuation';
import { ENGLISHROUTES, ROUTES } from '../../Utils/Routes';
import Policy from '../../Pages/Policy/Policy';
import Home from '../../Pages/Home/Home';

const AppRoutes: React.FC = () => {
  return (
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.VALUATION} element={<Valuation />} />
        <Route path={ENGLISHROUTES.VALUATION} element={<Valuation />} />
        <Route path={ROUTES.POLICY} element={<Policy />} />
      </Routes>
  )
};

export default AppRoutes;
