import { IServerResponse } from "../Models/IServerResponse";
import { IPdfGenerateReq } from "../Models/Requests/IPdfGenerateReq";
import axiosAuth from '../Setup/axios/AuthInstance';
import { ENDPOINTS } from "../Utils/Endpoints";

const useData = () => {
    const getAppsData = async (langId?: number): Promise<IServerResponse> => {
        try {
            const response = await axiosAuth.get(ENDPOINTS.GetApps + (langId ? `?LanguageId=${langId}` : ''));
            const rawData = response.data;
            return { succeed: true, data: rawData };
        } catch (error) {
            console.error('Error while fetching users data: ', error);
            return { succeed: false, message: error };
        }
    };

    const getPdfResults = async (req: IPdfGenerateReq) => {
        try {
            const response = await axiosAuth.post(
                ENDPOINTS.GetPdfResults,
                req,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },     
                },
            );
            
            const base64Pdf = response.data.pdfFile;
            const pdfData = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0));

            let fileName = response.data.fileName ? response.data.fileName : "Wycena " + Math.floor(Math.random() * (1000 - 1 + 1)) + ".pdf";

            const blob = new Blob([pdfData], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error pdf generating: ', error);
            return { succeed: false, message: error };
        }
    };

    return {
        getAppsData,
        getPdfResults,
    };
};
export default useData;